import React from 'react';
import './styles.css';

function App() {
  return (
    <div className="construction-container">
      <h1 className="title-bounce">Woorzit</h1>
      <h2 className="subtitle-fade">Se vienen cositas</h2>
    </div>
  );
}

export default App;
